<template>
  <div class="tableAdminTeacher">
    <a-modal @cancel="closeModalSchedule" :title="`Schedule for ${dataGuruSchedule.nama}`" centered :footer="null"
      :visible="scheduleTeacherIsVisible" :width="1200">
      <a-form-item class="d-none d-lg-block">
        <a-radio-group :value='toggleValue' button-style="solid" size="large" style="height: 48px" class="ml-2"
          @change="handleSelectView">
          <a-radio-button value="Schedule">Schedule</a-radio-button>
          <a-radio-button value="Extra">Extra</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <BoxScheduleAdmin v-if="toggleValue === 'Schedule'" :model="modelSchedule" :dataGuru='dataGuruSchedule'
        :slots="allSlot" @fetchScheduleTeacher="fetchDataSchedule(dataGuruSchedule.id)" />
      <div v-if="toggleValue === 'Extra'">
        <a-row :gutter="[8, 0]">
          <a-col :span="8">
            <a-form-item required label="Class" has-feedback>
              <a-select size="large" v-model="createEmptySchedule.id_kelas" style="width: 100%"
                @change="handleSelectClass">
                <a-select-option v-for="item in kelas" :key="item.id" :value="item.id">{{ item.level.nama }}
                  {{ item.program ? item.program.nama : null }} {{ item.simbol }}</a-select-option>
              </a-select>
              <!-- <small style="position: relative; color: red">Class is required!</small> -->
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item required label="Subject" has-feedback>
              <a-select size="large" style="width: 100%" v-model="createEmptySchedule.id_mata_pelajaran"
                @change="handleSelectMapel">
                <a-select-option v-for="item in mapel" :key="item.id" :value="item.id">{{ item.nama
                }}</a-select-option>
              </a-select>
              <!-- <small style="position: relative; color: red">Subject is required!</small> -->
            </a-form-item>
          </a-col>
          <a-col :span="8" v-if="!createEmptySchedule.id">
            <a-button @click.prevent="addAndEditEmptySchedule" size="large" type="primary"
              style="margin-top: 45px;"><a-icon type="plus" />Create Schedule</a-button>
          </a-col>
          <a-col :span="8" v-if="createEmptySchedule.id">
            <a-button icon="save" type="primary" size="large" :style="{
              width: '130px',
              height: '40px',
              marginTop: '45px',
            }" @click.prevent="addAndEditEmptySchedule">SAVE</a-button>
            <a-button type="danger" icon="close" size="large" class="ml-2" :style="{
              width: '130px',
              height: '40px',
              marginTop: '45px',
            }" @click.prevent="cancelUpdate">CANCEL</a-button>
          </a-col>
        </a-row>
        <a-table :columns="columnsExtraSchedule" :data-source="emptySchedule" :loading="loading" :pagination="false"
          rowKey="id" @change="handleTableChange" bordered>
          <!-- <span slot="Nama" slot-scope="text, record"><a class="linkHover" href="" @click.prevent="toDetailTeacher(record.key)">{{text}}</a></span> -->
          <!-- <span slot="Nama" slot-scope="text, record"><a class="linkHover" href="" @click.prevent="toScheduleTeacher(record)">{{text || '-'}}</a></span> -->
          <span slot="kela" slot-scope="text">{{ `${text.tingkat}-${text.simbol}` || '-' }}</span>
          <span slot="mata_pelajaran" slot-scope="text">
            <a-tag class="my-2" color="blue">{{ text.nama }}</a-tag>
          </span>
          <span slot="program" slot-scope="text">
            <a-tag class="my-2" color="green">{{ text.nama }}</a-tag>
          </span>
          <span slot="Actions" slot-scope="text, record">
            <a-button type="primary" size="large" style="margin-right: 5px;"
              @click.prevent="handleEditData(record)"><a-icon type="edit" /></a-button>
            <a-button type="danger" size="large" @click.prevent="deleteEmptySchedule(record.id)"><a-icon
                type="delete" /></a-button>
          </span>
          <!-- <span slot="Actions" slot-scope="text, record">
        <a @click.prevent="toScheduleTeacher(record)" class="btn btn-outline-primary mx-2">Schedule</a>
      </span> -->
        </a-table>
      </div>
    </a-modal>
    <!-- <div>
      <div class="cui__utils__heading mb-0">
        <strong>Rules</strong>
      </div>
      <div
        class="text-muted mb-3"
      >On this page you can view, search, add, edit, change password, and delete teachers</div>
      <hr />
    </div> -->
    <div :class="[!isMobile ? 'd-flex' : '', !isMobile ? 'flex-nowrap' : '']">
      <div class="d-flex">
        <a-form-item>
          <a-input v-model="search" @keyup="searchTeacher" placeholder="Search by NIK or Nama.." :style="{
            width: isMobile ? '100%' : '225px',
            height: '40px'
          }"></a-input>
        </a-form-item>
        <!-- <div class="ml-3">
          <a-select style="min-width: 150px;" @change="handleChangeFilter" v-model="selectRole" size="large">
            <a-select-option value="">All Data</a-select-option>
            <a-select-option v-for="role in roles" :key="role.key" :value="role.tag">{{role.name}}</a-select-option>
          </a-select>
        </div> -->
      </div>
      <!-- <div :class="['ml-auto', isMobile ? 'mb-4' : '']">
        <a-button :block="isMobile" @click="toAddTeacher" type="primary" icon="plus" style="height:40px">Add Teacher</a-button>
      </div> -->
    </div>
    <a-table :columns="columns" :data-source="dataTable" :loading="loading" :pagination="pagination"
      @change="handleTableChange" bordered>
      <!-- <span slot="Nama" slot-scope="text, record"><a class="linkHover" href="" @click.prevent="toDetailTeacher(record.key)">{{text}}</a></span> -->
      <!-- <span slot="Nama" slot-scope="text, record"><a class="linkHover" href="" @click.prevent="toScheduleTeacher(record)">{{text || '-'}}</a></span> -->
      <span slot="Nama" slot-scope="text">{{ text || '-' }}</span>
      <span slot="Nik" slot-scope="text">{{ text ? text : '-' }}</span>
      <!-- <span slot="Role" slot-scope="text, record">
        <template v-for="(role, i) in record.Role">
          <a-tag class="my-2" color="blue" v-if="findRole(role, record.Role)" :key="i">{{findRole(role, record.Role).name}}</a-tag>
        </template>
      </span> -->
      <span slot="subject" slot-scope="text, record">
        <template v-for="(subject, idx) in record.subjects">
          <a-tag class="my-2" color="blue" v-bind:key="idx">{{ subject }}</a-tag>
        </template>
      </span>
      <span slot="Actions" slot-scope="text, record">
        <a @click.prevent="toScheduleTeacher(record)" class="btn btn-outline-primary mx-2">Schedule</a>
      </span>
    </a-table>
  </div>
</template>

<script>
import { roles } from '@/helpers/roles.js'
// import { reactive } from 'vue'
// import moment from 'moment'
const BoxScheduleAdmin = () => import('@/components/app/Admin/BoxScheduleAdmin')
const columns = [
  {
    title: 'NIK',
    dataIndex: 'Nik',
    // width: '15%',
    scopedSlots: {
      customRender: 'Nik',
      filterIcon: 'filterIcon',
    },
  },
  {
    title: 'Name',
    dataIndex: 'Nama',
    sorter: true,
    scopedSlots: { customRender: 'Nama' },
    // width: '32%',
  },
  // {
  //   title: 'Role',
  //   dataIndex: 'Role',
  //   scopedSlots: { customRender: 'Role' },
  //   // width: '32%',
  // },
  {
    title: 'Subjects',
    dataIndex: 'subjects',
    scopedSlots: { customRender: 'subject' },
    // width: '32%',
  },
  {
    title: 'Actions',
    dataIndex: 'Actions',
    align: 'center',
    // width: '20%',
    scopedSlots: { customRender: 'Actions' },
  },
]
const columnsExtraSchedule = [
  {
    title: 'Kelas',
    dataIndex: 'kela',
    // width: '15%',
    align: 'center',
    width: '20%',
    scopedSlots: {
      customRender: 'kela',
      filterIcon: 'filterIcon',
    },
  },
  {
    title: 'Mata Pelajaran',
    dataIndex: 'mata_pelajaran',
    // width: '15%',
    align: 'center',
    width: '20%',
    scopedSlots: {
      customRender: 'mata_pelajaran',
      filterIcon: 'filterIcon',
    },
  },
  {
    title: 'Program',
    dataIndex: 'kela.program',
    // width: '15%',
    align: 'center',
    width: '20%',
    scopedSlots: {
      customRender: 'program',
      filterIcon: 'filterIcon',
    },
  },
  {
    title: 'Actions',
    dataIndex: 'Actions',
    align: 'center',
    width: '10%',
    scopedSlots: { customRender: 'Actions' },
  },
]
// const editableData = reactive({})
export default {
  data() {
    return {
      search: '',
      columns,
      columnsExtraSchedule,
      dataTable: [],
      pagination: {},
      loading: false,
      roles: roles.filter(role => role.tag !== 'pembinaEkskul'),
      selectRole: '',
      dataGuruSchedule: {
        nama: '',
        id: null,
      },
      scheduleTeacherIsVisible: false,
      allSlot: [],
      loadingDataSchedule: false,
      modelSchedule: null,
      toggleSchedule: true,
      toggleExtra: false,
      toggleValue: 'Schedule',
      kelas: [],
      mapel: [],
      emptySchedule: [],
      createEmptySchedule: {
        id: '',
        id_kelas: 'Select Class...',
        id_mata_pelajaran: 'Select Subject...',
      },

    }
  },
  props: ['idTahunAjaran'],
  components: { BoxScheduleAdmin },
  methods: {
    cancelUpdate() {
      this.createEmptySchedule = {
        id: '',
        id_kelas: 'Select Class...',
        id_mata_pelajaran: 'Select Subject...',
      }
    },
    handleEditData(data) {
      this.createEmptySchedule.id = data.id
      this.createEmptySchedule.id_kelas = data.id_kelas
      this.createEmptySchedule.id_mata_pelajaran = data.id_mata_pelajaran
    },
    handleSelectClass(id) {
      this.createEmptySchedule.id_kelas = id
    },
    handleSelectMapel(id) {
      this.createEmptySchedule.id_mata_pelajaran = id
    },
    async deleteEmptySchedule(id) {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure you want to delete empty schedule ? your data before will be lost and your changes will be saved on log.</div>
        ),
        onOk: () => {
          this.$store.dispatch('admin/DELETE_EMPTY_SCHEDULE', {
            idSlot: id,
          })
          this.fetchEmptySchedule(this.dataGuruSchedule.id)
          this.$notification.success({
            message: 'Success.',
            description: 'Your Empty Schedule has been deleted',
          })
          this.createEmptySchedule = {
            id: '',
            id_kelas: 'Select Class...',
            id_mata_pelajaran: 'Select Subject...',
          }
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Yes',
      })
    },
    async addAndEditEmptySchedule() {
      try {
        const idData = this.createEmptySchedule.id
        if (this.createEmptySchedule.id_kelas === 'Select Class...' || this.createEmptySchedule.id_mata_pelajaran === 'Select Subject...') {
          return this.$notification.error({
            message: 'Warning',
            description: 'Select Class or Subject before submitting',
          })
        } else {
          const data = await this.$store.dispatch('admin/POST_EMPTY_SCHEDULE', {
            idGuru: this.dataGuruSchedule.id,
            idSlot: this.createEmptySchedule.id,
            idKelas: this.createEmptySchedule.id_kelas,
            idMataPelajaran: this.createEmptySchedule.id_mata_pelajaran,
            idTahunAjaran: this.idTahunAjaran,
          })
          if (data.message === 'success update empty schedule' || data.message === 'success create empty schedule') {
            await this.fetchEmptySchedule(this.dataGuruSchedule.id)
            this.createEmptySchedule = {
              id: '',
              id_kelas: 'Select Class...',
              id_mata_pelajaran: 'Select Subject...',
            }
            if (idData) {
              this.$notification.success({
                message: 'Success.',
                description: 'Your Empty Schedule has been updated',
              })
            } else {
              this.$notification.success({
                message: 'Success.',
                description: 'Your Empty Schedule has been added',
              })
            }
          } else {
            throw new Error()
          }
        }
      } catch (err) {
        this.$notification.error({
          message: 'Warning',
          description: 'Error creating/updating data',
        })
      }
      // console.log(this.createEmptySchedule)
    },
    async fetchClassAndMapel(id) {
      try {
        const { kelas, mapel } = await this.$store.dispatch('admin/FETCH_CLASS_AND_SUBJECT_BY_SLOT', {
          idGuru: id,
          idTahunAjaran: this.idTahunAjaran,
        })
        this.kelas = kelas
        this.mapel = mapel
      } catch (err) {
        console.log(err)
      }
    },
    async fetchEmptySchedule(id) {
      try {
        const { slot } = await this.$store.dispatch('admin/FETCH_EMPTY_SCHEDULE', {
          idGuru: id,
          idTahunAjaran: this.idTahunAjaran,
        })
        this.emptySchedule = slot
      } catch (err) {
        console.log(err)
      }
    },
    handleSelectView(e) {
      // const delay = t => new Promise(resolve => setTimeout(resolve, t))
      if (e.target.value === 'Schedule') {
        this.toggleValue = 'Schedule'
      } else {
        this.toggleValue = 'Extra'
      }
    },
    handleChangeFilter() {
      this.fetchData({ search: this.search })
    },
    findRole(roleData, allRole) {
      const data = this.roles.find(role => { return roleData === role.tag })
      // console.log(data)
      const newData = data ? { ...data } : null
      if (newData?.children) {
        // console.log(newData?.children, 'masuk')
        const children = newData.children.find(role => { return allRole.includes(role.tag) })
        // console.log(children, newData, allRole)
        if (children) newData.name += (' Bag. ' + children.name)
      }
      return newData
    },
    toAddTeacher() {
      this.$router.push({ name: 'Add Teacher Admin' })
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Teacher Admin'],
      })
    },
    toDetailTeacher(id) {
      this.$router.push({ name: 'Detail Teacher Admin', params: { id } })
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Teacher Admin'],
      })
    },
    // toScheduleTeacher(id) {
    //   this.$router.push({ name: 'Schedule Teacher Admin', params: { id } })
    //   this.$store.commit('menu/SET_STATE', {
    //     currentMenu: ['Teacher Admin'],
    //   })
    // },
    async toScheduleTeacher(data) {
      try {
        // console.log(data)
        const { key: id, Nama } = data
        if (!this.modelSchedule) {
          return this.$notification.error({
            message: 'Warning',
            description: 'You did not activate schedule setting yet, please activate it from Master Data.',
          })
        }
        this.loadingDataSchedule = true
        const haveSubject = await this.checkSubject(id)
        if (!haveSubject) {
          return this.$notification.error({
            message: 'Warning',
            description: `${Nama} still don't have subject yet. Edit on master 'Teacher' to continue.`,
          })
        }
        await this.fetchDataSchedule(id)
        await this.fetchClassAndMapel(id)
        await this.fetchEmptySchedule(id)

        this.dataGuruSchedule.id = id
        this.dataGuruSchedule.nama = Nama
        this.scheduleTeacherIsVisible = true
      } catch (err) {
        console.log(err)
      }
      // console.log(id)
    },
    searchTeacher() {
      if (this.search.length > 2) {
        this.fetchData({
          search: this.search,
        })
      } else {
        this.fetchData()
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      let order = ''
      pager.current = pagination.current
      this.pagination = pager
      if (sorter.order === 'ascend') {
        order = 'ASC'
      } else if (sorter.order === 'descend') {
        order = 'DESC'
      } else {
        order = 'ASC'
      }
      this.fetchData({
        page: pagination.current,
        order: order,
      })
    },
    fetchData(params = { page: 1, order: 'ASC', search: '' }) {
      this.loading = true
      this.$store.dispatch('admin/FETCH_TEACHERS', { page: params.page, order: params.order, search: params.search, role: this.selectRole })
        .then(data => {
          // console.log(data)
          const pagination = { ...this.pagination }
          pagination.total = data.total
          this.loading = false
          this.dataTable = data.guru.map(row => {
            const subjects = []
            row.slots.forEach(slot => {
              const namaMapel = slot.mata_pelajaran?.nama
              if (namaMapel) {
                if (subjects.find(sub => sub === namaMapel)) {
                } else subjects.push(namaMapel)
              }
            })
            const isPrincipal = Boolean(row.periode_manajemen_atas.length)
            // console.log(JSON.parse(row.role))
            const roles = JSON.parse(row.role)
            if (isPrincipal) roles.push('kepalaSekolah')
            return {
              key: row.id,
              Nik: row.NIK,
              Nama: row.nama,
              Role: roles,
              subjects,
            }
          })
          this.pagination = pagination
        })
    },
    async checkSubject(idGuru) {
      try {
        const isExist = await this.$store.dispatch('admin/CHECK_SUBJECT', { idGuru })
        return new Promise((resolve) => resolve(isExist))
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async fetchDataSchedule(idGuru) {
      try {
        const { allSlot } = await this.$store.dispatch('admin/FETCH_SCHEDULE_TEACHER', { idGuru, idTahunAjaran: this.idTahunAjaran })
        this.loadingDataSchedule = false
        if (!allSlot.length) {
          this.allSlot = []
          return new Promise((resolve) => {
            resolve(false)
          })
        }
        this.allSlot = allSlot.filter(slot => slot.posisi !== null)
        return new Promise((resolve) => {
          resolve(true)
        })
      } catch (err) {
        console.log(err)
        this.loadingDataSchedule = false
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    closeModalSchedule() {
      this.idGuruSchedule = null
      this.scheduleTeacherIsVisible = false
      this.dataGuruSchedule.nama = ''
      this.createEmptySchedule = {
        id: '',
        id_kelas: 'Select Class...',
        id_mata_pelajaran: 'Select Subject...',
      }
      // this.toggleSchedule = true
      // this.toggleExtra = false
      this.toggleValue = 'Schedule'
    },
    async fetchModelActiveSchedule() {
      try {
        const data = await this.$store.dispatch('slot/FETCH_MODEL_ACTIVE_SCHEDULE')
        this.modelSchedule = data
      } catch (err) {
        console.log(err)
      }
    },
  },
  created() {
    // this.fetchDataSchedule()
    this.fetchData()
    this.fetchModelActiveSchedule()
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
}
</script>

<style lang = 'scss'>
.tableAdminTeacher {
  .linkHover:hover {
    text-decoration: underline;
  }

  .btn.btn-outline-danger,
  .show>.btn.btn-outline-danger {
    border-color: #f56a79 !important;
    color: white !important;
    color: #f56a79 !important;
  }

  .ant-spin-nested-loading .ant-spin-container .ant-table {
    overflow-x: auto;

    table {
      @media (max-width: 769px) {
        width: auto;
      }
    }
  }

  .btn.btn-outline-danger:hover,
  .btn.btn-outline-danger:active,
  .show>.btn.btn-outline-danger:hover,
  .show>.btn.btn-outline-danger:active {
    background-color: #f56a79 !important;
    color: white !important;
    border-color: #f56a79 !important;
  }

  .btn-outline-danger:not(:disabled):not(.disabled).active,
  .btn-outline-danger:not(:disabled):not(.disabled):active,
  .show>.btn-outline-danger.dropdown-toggle {
    background-color: #f56a79 !important;
    color: white !important;
    border-color: #f56a79 !important;
  }
}
</style>
